import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, ApplicationConfig, LOCALE_ID, importProvidersFrom, isDevMode } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRouteProviders } from './app-routing.module';
import { Meta, provideClientHydration } from '@angular/platform-browser';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { ConfigService } from './services/config.service';
import { MatDateFormats, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';
import { HttpUrlInterceptor } from './http-url.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';

function initializeAppFactory(confs: ConfigService): () => Promise<any> {
    return () => confs.init();
}

const GRI_DATE_FORMATS: MatDateFormats = {
    ...MAT_NATIVE_DATE_FORMATS,
    display: {
        ...MAT_NATIVE_DATE_FORMATS.display,
        dateInput: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        } as Intl.DateTimeFormatOptions,
    }
};

export const appConfig: ApplicationConfig = {
    providers: [
        ...AppRouteProviders,
        // provideExperimentalZonelessChangeDetection(),
        provideAnimations(),
        provideHttpClient(withFetch(), withInterceptors([HttpUrlInterceptor])),
        provideClientHydration(),
        importProvidersFrom(ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            // registrationStrategy: 'registerWhenStable:30000'
            registrationStrategy: 'registerImmediately'
        })),
        { provide: MAT_DATE_FORMATS, useValue: GRI_DATE_FORMATS },
        Meta,
        {
            provide: LOCALE_ID,
            useValue: 'de-DE'
        },
        { provide: APP_ID, useValue: 'cleverreisen' },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: '6LfRh1IeAAAAAHtWRke9B5YG-QTnu3HpBhzAckg0'
        },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: CleverInterceptor,
        //     multi: true
        // },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [ConfigService], // <-- Here
            multi: true
        },
    ],
};
